import React, {useMemo, Fragment } from "react";
import {Accordion} from "src/components/commonComponents";
import "../../../node_modules/bulma-extensions/bulma-accordion/dist/css/bulma-accordion.min.css";
import TopResults from "./TopResults";
/**
 *
 * @param {Obejct} filterOptions - Object in which each key name is a type of filter
 * Inside this Object must have keys with arrays, in which can be clicked to filter
 * search page
 */
const FilterSideMenu = ({ filterOptions, onFilterClick, locale }) => {
  const filterOptionsKeys = Object.keys(filterOptions);
  const handleInnerFilterClick = (e, option, filterKey) => {
    e.preventDefault();
    onFilterClick(option, filterKey); // filterKey = lead in parent component
  };

  const content = useMemo(() => {
    const desktopContent = filterOptionsKeys.map((filterKey) => (
      <aside className="filter-container" key={filterKey}>
        <div className="divider tw-border tw-border-accruent_lilac tw-opacity-100 tw-mx-4 tw-mt-[17px] tw-mb-4"></div>
        <h5 className="filter-title is-capitalized ml-2 tw-text-base tw-font-Poppins tw-font-bold tw-text-accruent_heading_color tw-leading-[23.2px] tw-tracking-[0.14px] tw-pb-4">{filterKey}</h5>
        <ul className="menu-list">
          {filterOptions[filterKey].map((option) => {
            const isActive = option.isActive;
            let label;
            if (option.name) {
              label = option.name.replace("_", " ");
              if (label === "Blog Entries") label = "Blog Posts";
            }
            return (
              <li key={option.name}>
                {/* eslint-disable-next-line */}
                <a className="!tw-pl-4 tw-text-xs tw-font-Roboto tw-text-accruent_black_olive tw-leading-4 tw-tracking-[0.4px]"
                  onClick={(e) => handleInnerFilterClick(e, option, filterKey)}
                >
                  <div className="columns is-mobile is-gapless">
                    <div className="column checkbox !tw-flex-none !tw-mr-2">
                      <input
                        type="checkbox"
                        // className="mr-2"
                        id={option.name}
                        name={option.name}
                        checked={isActive}
                        key={isActive}
                      />
                    </div>
                    <div className="column">
                      <label
                        className="checkbox is-capitalized !tw-flex-none !tw-mr-2"
                        htmlFor={option.name}
                      >
                        {label}
                      </label>
                    </div>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </aside>
    ));
    const mobileContent = filterOptionsKeys.map((filterKey) => {
      const content = filterOptions[filterKey].map((option) => {
        const isActive = option.isActive;
        return (
          <button
            className="button is-full-width is-justify-content-left is-align-items-start has-text-left has-height-auto is-multiline-text"
            onClick={(e) => handleInnerFilterClick(e, option, filterKey)}
            key={option.id}
          >
            <div className="mr-1 is-pulled-left">
              <input
                type="checkbox"
                id={option.name}
                name={option.name}
                checked={isActive}
                key={isActive}
              />
            </div>

            <label
              className="checkbox is-capitalized has-text-black"
              htmlFor={option.name}
            >
              {option.name.replace("_", " ")}
            </label>
          </button>
        );
      });
      return {
        title: filterKey,
        content: <Fragment>{content}</Fragment>,
        id: filterKey,
      };
    });
    return { desktopContent, mobileContent };
  }, [filterOptions]);

  return (
    <aside className="menu filter-side-menu">
      <div className="is-hidden-mobile">
        <div className="left-content-container tw-bg-subtitle_color tw-bg-no-repeat tw-bg-0% tw-bg-origin-padding tw-rounded-2xl">
          <div className="filter-with-icon tw-flex tw-items-center tw-pt-5 tw-pl-4 ">
            <i className="fa-regular fa-filter-list tw-text-2xl tw-text-link-color"></i>
            <p className="filter tw-text-xl tw-tracking-[0.18px] tw-leading-[23.2px] tw-text-link-color tw-font-bold tw-font-Poppins tw-m-0 tw-pl-2">Filters</p>
          </div>
          {content.desktopContent}
        </div>
        <TopResults locale={locale}/>
      </div>
      <div className="is-hidden-tablet">
        <Accordion
          data={content.mobileContent}
          id="search-side-menu-collapsible"
          isCapitalized
        />
      </div>
    </aside>
  );
};

export default FilterSideMenu;
